// TODO: Rework all Icon system!!!

type IconProps = {
    height?: number
    width?: number
    viewBox?: string
    fill?: string
    className?: string
    hidden?: boolean
    opacity?: number
    children: React.ReactNode
}

export default function Icon({
    height = 24,
    width = 24,
    viewBox = '0 0 24 24',
    fill = 'none',
    className,
    hidden,
    opacity,
    children,
}: IconProps) {
    return (
        <svg
            height={height}
            width={width}
            viewBox={viewBox}
            fill={fill}
            className={className}
            // TODO: remove this? The only place is's used is the dropzone, which doesn't show when hidden=true anyways
            // packages/website/src/layouts/MountainInformationNetwork/submit/schema/widgets/Images/index.js
            // @ts-ignore
            hidden={hidden}
            style={{ opacity }}>
            {children}
        </svg>
    )
}
